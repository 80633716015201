<template>
    <div>
        <div class="page-title">会员等级设置</div>


        <div class="levels">

            <template v-for="(level,lk) in levels">

                <div v-if="!level.del" class="level" :key="lk" :data-name="level">

                    <span class="level-no">LV{{ lk + 1 }}</span>

                    <at-input v-model="level.name" width="300px">
                        <div v-if=" levelLength > 1 " @click="delLevel(level)"
                             class="input-after input-after-delete"></div>
                    </at-input>

                    <button @click="addLevel(lk)" class="btn btn-add"></button>
                </div>

            </template>

        </div>

        <button @click="saveLevel" class="btn btn-submit">提交</button>


    </div>
</template>

<script>
import AtInput from "../../../components/AtInput";
import api from "../../../repo/api";

export default {
    name: "Level",
    components: {AtInput},
    data() {
        return {
            levels: [''],
            levelLength: 0,
        }
    },
    mounted() {
        this.getLevels()
    },
    methods: {
        getLevels() {
            this.$loading.show()

            api.get('/ast-app/member-levels', (data) => {

                this.levels = data.data.length ? data.data : []

                if (!this.levels.length) this.addLevel()

                this.$loading.hide()

                this.sumLevelLength()
            });
        },

        saveLevel() {

            this.$loading.show()

            api.post('/ast-app/update-member-levels', {levels: this.levels}, (data) => {

                this.$message.data(data)

                this.getLevels()
            });
        },

        addLevel(idx) {
            this.levels.splice(idx + 1, 0, {
                name: ""
            });

            this.sumLevelLength()

            this.$forceUpdate()
        },
        delLevel(level) {

            if (this.levelLength === 1) return;

            if (!level.id) {

                let idx = this.levels.indexOf(level)

                this.levels.splice(idx, 1)

                this.sumLevelLength()

                this.$forceUpdate()

            } else {

                this.$confirm({
                    title: '删除会员等级，此等级下用户将变成游客',
                    onOk: () => {
                        level.del = 1

                        this.sumLevelLength()

                        this.$forceUpdate()
                    }
                })

            }
        },
        sumLevelLength() {

            let length = 0

            this.levels.forEach(item => {
                if ((!item.del) || item.del <= 0) length++
            })


            return this.levelLength = length;
        }
    }
}
</script>

<style lang="less" scoped>
.level {
    display: flex;
    align-items: center;
    margin-bottom: 32px;

    .level-no {
        width: 51px;
        font-size: 16px;
        font-weight: 400;
        color: #333333;
        line-height: 16px;
    }

    .btn-add {
        margin-left: 24px;
    }

    &:hover {
        .btn-add {
            display: block;
        }

        .input-after {
            display: block;
        }
    }

}

.input-after-delete {
    cursor: pointer;
}

.btn-add {
    display: none;
    width: 32px;
    height: 32px;
    background-image: url("/assets/icons/btn-add.png");
    background-size: 12px;
    background-position: center;
    background-repeat: no-repeat;
    padding: 0;
}

.btn-submit {
    margin-top: 88px;
    width: 82px;
    height: 32px;
    background: #333333;
    font-size: 14px;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 32px;
    padding: 0;
}

</style>